import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Button from "@material-ui/core/es/Button/Button";
import Logo from "./img/Logo.svg"
import Menu from "./components/Menu";
import Meta from "./components/Meta";
import Portfolio from "./components/Portfolio";
import Contacts from "./components/Contacts";
import Branding from "./components/Branding";

const fullpageOptions = {
    licenceKey:"48E41AFE-40984178-B7E8917D-C4B3C09E",
    autoScrolling:true,
    continuousHorizontal:true
};

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
    require('fullpage.js/vendors/scrolloverflow'); // Optional. When using scrollOverflow:true
};


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

    /*Pre loader stuff */
    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 2000))
    }

    componentDidMount(){
        this.authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator');
            if(ele){
                // fade out
                ele.classList.add('available');
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                }, 2000)
               /* setInterval(() => {
                    {{this.prop.fullpageApi.moveSlideRight()}}
                    console.log ("anton")
                }, 5000);*/
            }
        });

    }
    /* Pre loader stuff */




  onLeave(origin, destination, direction) {
    console.log('onLeave', { origin, destination, direction });
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
  }


  render() {

    return (
        <div className="App">
            <Meta/>
            <Contacts/>
            <Menu/>
            <Branding/>
            <img className="logo" src={Logo}/>
          <ReactFullpage
              scrollOverflow
              navigation
              auto
              onLeave={this.onLeave.bind(this)}
              pluginWrapper={pluginWrapper}
              render={({comp, fullpageApi})  => (
                  <ReactFullpage.Wrapper>
                    <div className="section">
                        <div  className="sf">

                            <section className="h1">
                                <h1>SteelFurniture.eu</h1>
                                <h3>Nordic Contempary Furniture,<br/> made with love in Estonia</h3>
                            </section>
                            <Button className="btn" size="large" color="primary" onClick={() => {{fullpageApi.moveSectionDown()}}}>
                                <i className="fas fa-angle-double-down"></i>
                            </Button>
                        </div>

                      <div className="slide slide1">

                      </div>
                      <div className="slide slide2">

                      </div>
                      <div className="slide slide3">

                      </div>

                    </div>
                    <div className="section instagram">
                        <div className="wrapper">
                            <Button className="btn" size="large" color="secondary" onClick={() => {{fullpageApi.moveSectionUp()}}}>
                            <i className="fas fa-angle-double-up"></i>
                            </Button>
                            <Portfolio/>
                        </div>
                      <div>

                      </div>
                    </div>
                  </ReactFullpage.Wrapper >

              )}
          />
        </div>
    );
  }
}
export default App;