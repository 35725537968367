import React from "react";
import Button from "@material-ui/core/es/Button/Button";

const Menu = () => (
    <nav className="menu">
        <Button href="https://www.instagram.com/steelfurniture.eu/"  rel="noopener noreferrer" color="secondary" ><i className="fab fa-instagram"></i></Button>
        <Button href="https://www.facebook.com/steelfurniture.eu/"  rel="noopener noreferrer" color="secondary" ><i className="fab fa-facebook-square"></i></Button>
    </nav>
);

export default Menu;