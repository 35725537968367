import React from 'react';
import Button from "@material-ui/core/es/Button/Button";



class Portfolio extends React.Component {

    componentDidMount() {
        fetch('https://api.instagram.com/v1/users/self/media/recent?access_token=4276741178.278b7eb.084936b8858a40b8b2c2690893c90180&count=8')
            .then(response => response.json())
            .then (instagram =>
                {
                    const myCard = document.querySelector('#card');
                    const newCard = document.createElement("span");
                    for (let i = 0; i < instagram.data.length; i++) {
                        myCard.innerHTML += '<a class="instagram-item"  rel="noopener noreferrer" href="'+ instagram.data[i].link +'" style="background-image: url(' + instagram.data[i].images.low_resolution.url + ')" target="_blank" ></a>' ;
                    }
                    myCard.appendChild(newCard);
                }
            )
    }
    render() {

        return (
        <React.Fragment>
            <div  id="card" >
                <h2>Find our works on Instagram</h2>
                <Button className="btn"  rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/steelfurniture.eu/" size="large" variant="contained"  color="primary">
                    Instagram
                </Button>
            </div>

        </React.Fragment>
        );
    }
}
export default Portfolio;