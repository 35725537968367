import React from "react";


const Branding = () => (
    <footer
        className="branding"
    >
        <i>Developed by <br/><a target="_blank" rel="noopener noreferrer" href="https://geekdev.ee/">GeekDev.ee</a></i>
    </footer>
);

export default Branding;