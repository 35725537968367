import Helmet from 'react-helmet';
import React from "react";

const Meta = () => (
<Helmet>
    <title>SteelFurniture.eu</title>
    <meta name="description" content="SteelFurniture.eu" />
    <meta name="og:description" content="Nordic Contempary Furniture, made with love in Estonia" />
    <meta property="og:title" content="SteelFurniture.eu" />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:locale:alternate" content="ru_RU" />
    <meta property="og:locale:alternate" content="et_EE" />
    <meta property="og:url" content="https://steelfurniture.eu/" />
    <meta property="og:image" content="/static/media/Logo.8d6d1fad.svg" />
</Helmet>
);

export default Meta;