import React from "react";


const Contacts = () => (
    <footer
        className="footer"

    >
    <address >
        <strong>Marianna Reinas</strong><br/>
        Founder / CEO<br/>
        +372 5129429<br/>
        <a href="mailto:info@steelfurniture.eu">info@steelfurniture.eu</a>
    </address>
    </footer>
);

export default Contacts;